<template>
  <div>
    <div v-if="loading" class="text-center">
      <a-spin tip="Loading..." />
    </div>
    <a-form-model
      v-if="!loading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <h2>基本情報</h2>
      <a-form-model-item ref="name" label="会社名" prop="name">
        <a-input
          v-model="form.name"
          @blur="
            () => {
              $refs.name.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="phonetic" label="ふりがな" prop="phonetic">
        <a-input
          v-model="form.phonetic"
          @blur="
            () => {
              $refs.phonetic.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item label="契約プラン" prop="plan" ref="plan">
        <a-select v-model="form.plan" placeholder="契約プランを選択してください。">
          <a-select-option v-for="item in plans" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="owned_number" label="保有台数" prop="owned_number">
        <a-input-number v-model="form.owned_number" :min="0" />
      </a-form-model-item>
      <a-form-model-item ref="max_user" label="ユーザーの上限" prop="max_user" extra="上限なしの場合は 0 にしてください。">
        <a-input-number v-model="form.max_user" :min="0" />
      </a-form-model-item>
      <a-form-model-item ref="max_analyze_user" label="解析対象ユーザーの上限" prop="max_user" extra="上限なしの場合は入力しないでください。">
        <a-input-number v-model="form.max_analyze_user" :min="0" />
      </a-form-model-item>
      <a-form-model-item label="備考" ref="comment">
        <a-input v-model="form.comment" type="textarea" />
      </a-form-model-item>

      <h2>表示関連</h2>
      <a-form-model-item ref="only_show_user_in_belong_branch" label="支店管理者のユーザー表示" prop="only_show_user_in_belong_branch">
        <a-radio-group v-model="form.only_show_user_in_belong_branch">
          <a-radio :value="0">全てのユーザーを表示</a-radio>
          <a-radio :value="1">所属支店のユーザーのみを表示</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="show_drive_monitor" label="運行状況の表示" prop="show_drive_monitor">
        <a-radio-group v-model="form.show_drive_monitor">
          <a-radio :value="1">表示する</a-radio>
          <a-radio :value="0">表示しない</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="show_trajectory" label="運行の軌跡の表示" prop="show_trajectory">
        <a-radio-group v-model="form.show_trajectory">
          <a-radio :value="1">表示する</a-radio>
          <a-radio :value="0">表示しない</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="use_analyze" label="解析の使用" prop="use_analyze">
        <a-radio-group v-model="form.use_analyze">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="use_announce" label="NOWの使用" prop="use_announce">
        <a-radio-group v-model="form.use_announce">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="use_raw_download" label="生データダウンロードの使用" prop="use_raw_download">
        <a-radio-group v-model="form.use_raw_download">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="show_user_fleet_flag" label="ユーザー一覧での解析対象マークの表示" prop="show_user_fleet_flag">
        <a-radio-group v-model="form.show_user_fleet_flag">
          <a-radio :value="0">非表示</a-radio>
          <a-radio :value="1">表示</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="show_user_roll_call_agent" label="ユーザー一覧での点呼代行マークと残り点呼数の表示" prop="show_user_roll_call_agent">
        <a-radio-group v-model="form.show_user_roll_call_agent">
          <a-radio :value="0">非表示</a-radio>
          <a-radio :value="1">表示</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="concat_vehicle_name_and_no" label="車両名と車両番号をカンマ区切りで表示" prop="concat_vehicle_name_and_no">
        <a-radio-group v-model="form.concat_vehicle_name_and_no">
          <a-radio :value="0">車両名のみ</a-radio>
          <a-radio :value="1">区切って表示</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <h2>点呼記録簿関連</h2>
      <a-form-model-item ref="multiple_alcohol_check" label="点呼記録を1日に複数回使用" prop="multiple_alcohol_check">
        <a-radio-group v-model="form.multiple_alcohol_check">
          <a-radio :value="0">1回のみ</a-radio>
          <a-radio :value="1">複数回</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="can_update_alcohol_measurement_result" label="アルコール濃度の編集" prop="can_update_alcohol_measurement_result">
        <a-radio-group v-model="form.can_update_alcohol_measurement_result">
          <a-radio :value="0">不可</a-radio>
          <a-radio :value="1">可能</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="skip_roll_call_before" label="乗車前点呼のスキップ" prop="skip_roll_call_before">
        <a-radio-group v-model="form.skip_roll_call_before">
          <a-radio :value="0">不可</a-radio>
          <a-radio :value="1">可能</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="separation_of_days" label="1日の区切り時間" prop="separation_of_days">
        <a-time-picker v-model="separationOfDays" format="HH:mm" @change="onChangeSeparationOfDays" />
        <p>点呼記録簿の日付計算のために使用されます。</p>
      </a-form-model-item>
      <a-form-model-item label="点呼代行オペレーターへのメモ" ref="memo_for_operator">
        <a-input v-model="form.memo_for_operator" type="textarea" />
      </a-form-model-item>
      <h2>オペレータ関連</h2>
      <a-form-model-item ref="roll_call_agent" label="点呼代行を行う会社か" prop="roll_call_agent">
        <a-radio-group v-model="form.roll_call_agent">
          <a-radio :value="0">行わない</a-radio>
          <a-radio :value="1">オペレータを持ち、点呼代行の電話を受け付ける</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <h2>違反マップ関連</h2>
      <a-form-model-item ref="limit_latitude_left_top" label="データ取得制限：左上（緯度）" prop="limit_latitude_left_top" extra="無制限に取得可能にする場合は0を入力してください。">
        <a-input-number v-model="form.limit_latitude_left_top" :min="0" />
      </a-form-model-item>
      <a-form-model-item ref="limit_longitude_left_top" label="データ取得制限：左上（経度）" prop="limit_longitude_left_top" extra="無制限に取得可能にする場合は0を入力してください。">
        <a-input-number v-model="form.limit_longitude_left_top" :min="0" />
      </a-form-model-item>
      <a-form-model-item ref="limit_latitude_right_bottom" label="データ取得制限：右下（緯度）" prop="limit_latitude_right_bottom" extra="無制限に取得可能にする場合は0を入力してください。">
        <a-input-number v-model="form.limit_latitude_right_bottom" :min="0" />
      </a-form-model-item>
      <a-form-model-item ref="limit_longitude_right_bottom" label="データ取得制限：右下（経度）" prop="limit_longitude_right_bottom" extra="無制限に取得可能にする場合は0を入力してください。">
        <a-input-number v-model="form.limit_longitude_right_bottom" :min="0" />
      </a-form-model-item>
      <h2>API用項目</h2>
      <a-form-model-item ref="username" label="API用ユーザー名" prop="username">
        <a-input
          v-model="form.username"
          @blur="
            () => {
              $refs.username.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="password" label="API用パスワード" prop="password">
        <a-input
          v-model="form.password"
        />
      </a-form-model-item>
      <a-form-model-item ref="api_vehicle" label="API用車両ID" prop="api_vehicle">
        <a-input-number v-model="form.api_vehicle" :min="0" />
      </a-form-model-item>

      <hr>
      <h2>解析プログラムのパラメータ</h2>
      <a-form-model-item ref="termination_rate" label="一時停止判定の速度" prop="termination_rate">
        <a-input-number v-model="form.termination_rate" :min="0" :max="20" />
      </a-form-model-item>
      <a-form-model-item ref="overspeed" label="速度超過のしきい値" prop="overspeed">
        <a-input-number v-model="form.overspeed" :min="0" :max="100" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          {{ submitString }}
        </a-button>
        <router-link :to="backPath">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>

import Vue from 'vue'
import moment from 'moment'
import { enumData } from '@/services/enum'

export default {
  name: 'FormCompany',
  data() {
    return {
      loading: true,
      backPath: '/master/companies',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      company: this.companyProps,
      form: {
        name: '',
        phonetic: '',
        plan: undefined,
        max_user: 0,
        max_analyze_user: null,
        comment: '',
        memo_for_operator: '',
        username: '',
        password: '',
        api_vehicle: null,
        show_drive_monitor: 1,
        show_trajectory: 1,
        use_analyze: 0,
        use_announce: 0,
        use_raw_download: 0,
        termination_rate: 2,
        overspeed: 10,
        separation_of_days: '00:00:00',
        owned_number: null,
        show_user_fleet_flag: 0,
        show_user_roll_call_agent: 0,
        multiple_alcohol_check: 0,
        concat_vehicle_name_and_no: 1,
        can_update_alcohol_measurement_result: 0,
        skip_roll_call_before: 0,
        roll_call_agent: 0,
        only_show_user_in_belong_branch: 0,
        limit_latitude_left_top: 0,
        limit_longitude_left_top: 0,
        limit_latitude_right_bottom: 0,
        limit_longitude_right_bottom: 0,
      },
      separationOfDays: moment('00:00', 'HH:mm'),
      plans: enumData.companyPlan,
      rules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        phonetic: [
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        plan: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        max_user: [
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        username: [
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        api_vehicle: [
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        show_drive_monitor: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        show_trajectory: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        use_analyze: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        use_announce: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        use_raw_download: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        termination_rate: [
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        overspeed: [
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        show_user_fleet_flag: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        show_user_roll_call_agent: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        multiple_alcohol_check: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        concat_vehicle_name_and_no: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        can_update_alcohol_measurement_result: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        skip_roll_call_before: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        roll_call_agent: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        only_show_user_in_belong_branch: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        limit_latitude_left_top: [
          { type: 'number', message: '数値を入力してください。', trigger: 'blur' },
        ],
        limit_longitude_left_top: [
          { type: 'number', message: '数値を入力してください。', trigger: 'blur' },
        ],
        limit_latitude_right_bottom: [
          { type: 'number', message: '数値を入力してください。', trigger: 'blur' },
        ],
        limit_longitude_right_bottom: [
          { type: 'number', message: '数値を入力してください。', trigger: 'blur' },
        ],
      },
    }
  },
  props: [
    'companyProps',
    'submitType',
    'submitString',
  ],
  watch: {
    company: function(val) {
      this.form.name = val.name
      this.form.phonetic = val.phonetic
      this.form.plan = val.plan
      this.form.owned_number = val.owned_number
      this.form.separation_of_days = val.separation_of_days
      this.form.max_user = val.max_user
      this.form.max_analyze_user = val.max_analyze_user
      this.form.comment = val.comment
      this.form.memo_for_operator = val.memo_for_operator
      this.form.username = val.username
      this.form.password = val.password
      this.form.api_vehicle = val.api_vehicle
      this.form.show_drive_monitor = val.show_drive_monitor
      this.form.show_trajectory = val.show_trajectory
      this.form.use_analyze = val.use_analyze
      this.form.use_announce = val.use_announce
      this.form.use_raw_download = val.use_raw_download
      this.form.show_user_fleet_flag = val.show_user_fleet_flag
      this.form.show_user_roll_call_agent = val.show_user_roll_call_agent
      this.form.concat_vehicle_name_and_no = val.concat_vehicle_name_and_no
      this.form.termination_rate = val.analysis_parameters.termination_rate
      this.form.overspeed = val.analysis_parameters.overspeed
      this.form.multiple_alcohol_check = val.multiple_alcohol_check
      this.form.can_update_alcohol_measurement_result = val.can_update_alcohol_measurement_result
      this.form.skip_roll_call_before = val.skip_roll_call_before
      this.form.roll_call_agent = val.roll_call_agent
      this.form.only_show_user_in_belong_branch = val.only_show_user_in_belong_branch
      this.form.limit_latitude_left_top = val.limit_latitude_left_top
      this.form.limit_longitude_left_top = val.limit_longitude_left_top
      this.form.limit_latitude_right_bottom = val.limit_latitude_right_bottom
      this.form.limit_longitude_right_bottom = val.limit_longitude_right_bottom
      if (val.separation_of_days) {
        this.separationOfDays = moment(val.separation_of_days, 'HH:mm')
      }
    },
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (_this.submitType === 'post') {
            const send = Vue.prototype.$api.send('post', 'companies', this.form)
            send.then(response => {
              console.log(response)
              _this.$notification['success']({
                message: this.form.name + ' を作成しました。',
              })
              _this.$router.push(this.backPath)
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          } else if (_this.submitType === 'put') {
            const send = Vue.prototype.$api.send('put', 'companies/' + _this.$route.params.id, this.form)
            send.then(response => {
              console.log(response)
              _this.$notification['success']({
                message: this.form.name + ' を更新しました。',
              })
              _this.$router.push(this.backPath)
            })
              .catch(error => {
                if (error.status === 403 || error.status === 400) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onChangeSeparationOfDays(time) {
      this.form.separation_of_days = time.format('HH:mm:ss')
    },
  },
  created() {
    if (this.submitType === 'put') {
      const result = Vue.prototype.$api.send('get', 'companies/' + this.$route.params.id)
      result.then(response => {
        console.log(response)
        this.loading = false
        this.company = response
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 会社の取得に失敗しました。',
          })
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
}
</script>
