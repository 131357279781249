var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("a-spin", { attrs: { tip: "Loading..." } })],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol
              }
            },
            [
              _c("h2", [_vm._v("基本情報")]),
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "会社名", prop: "name" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.name.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "phonetic",
                  attrs: { label: "ふりがな", prop: "phonetic" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.phonetic.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.phonetic,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phonetic", $$v)
                      },
                      expression: "form.phonetic"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "plan", attrs: { label: "契約プラン", prop: "plan" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "契約プランを選択してください。" },
                      model: {
                        value: _vm.form.plan,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "plan", $$v)
                        },
                        expression: "form.plan"
                      }
                    },
                    _vm._l(_vm.plans, function(item) {
                      return _c("a-select-option", { key: item.value }, [
                        _vm._v(
                          "\n          " + _vm._s(item.label) + "\n        "
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "owned_number",
                  attrs: { label: "保有台数", prop: "owned_number" }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.owned_number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "owned_number", $$v)
                      },
                      expression: "form.owned_number"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "max_user",
                  attrs: {
                    label: "ユーザーの上限",
                    prop: "max_user",
                    extra: "上限なしの場合は 0 にしてください。"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.max_user,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "max_user", $$v)
                      },
                      expression: "form.max_user"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "max_analyze_user",
                  attrs: {
                    label: "解析対象ユーザーの上限",
                    prop: "max_user",
                    extra: "上限なしの場合は入力しないでください。"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.max_analyze_user,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "max_analyze_user", $$v)
                      },
                      expression: "form.max_analyze_user"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "comment", attrs: { label: "備考" } },
                [
                  _c("a-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment"
                    }
                  })
                ],
                1
              ),
              _c("h2", [_vm._v("表示関連")]),
              _c(
                "a-form-model-item",
                {
                  ref: "only_show_user_in_belong_branch",
                  attrs: {
                    label: "支店管理者のユーザー表示",
                    prop: "only_show_user_in_belong_branch"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.only_show_user_in_belong_branch,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "only_show_user_in_belong_branch",
                            $$v
                          )
                        },
                        expression: "form.only_show_user_in_belong_branch"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("全てのユーザーを表示")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("所属支店のユーザーのみを表示")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "show_drive_monitor",
                  attrs: { label: "運行状況の表示", prop: "show_drive_monitor" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.show_drive_monitor,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "show_drive_monitor", $$v)
                        },
                        expression: "form.show_drive_monitor"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("表示する")
                      ]),
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("表示しない")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "show_trajectory",
                  attrs: { label: "運行の軌跡の表示", prop: "show_trajectory" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.show_trajectory,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "show_trajectory", $$v)
                        },
                        expression: "form.show_trajectory"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("表示する")
                      ]),
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("表示しない")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "use_analyze",
                  attrs: { label: "解析の使用", prop: "use_analyze" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.use_analyze,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "use_analyze", $$v)
                        },
                        expression: "form.use_analyze"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("使用しない")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("使用する")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "use_announce",
                  attrs: { label: "NOWの使用", prop: "use_announce" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.use_announce,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "use_announce", $$v)
                        },
                        expression: "form.use_announce"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("使用しない")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("使用する")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "use_raw_download",
                  attrs: {
                    label: "生データダウンロードの使用",
                    prop: "use_raw_download"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.use_raw_download,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "use_raw_download", $$v)
                        },
                        expression: "form.use_raw_download"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("使用しない")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("使用する")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "show_user_fleet_flag",
                  attrs: {
                    label: "ユーザー一覧での解析対象マークの表示",
                    prop: "show_user_fleet_flag"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.show_user_fleet_flag,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "show_user_fleet_flag", $$v)
                        },
                        expression: "form.show_user_fleet_flag"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("非表示")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("表示")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "show_user_roll_call_agent",
                  attrs: {
                    label: "ユーザー一覧での点呼代行マークと残り点呼数の表示",
                    prop: "show_user_roll_call_agent"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.show_user_roll_call_agent,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "show_user_roll_call_agent", $$v)
                        },
                        expression: "form.show_user_roll_call_agent"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("非表示")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("表示")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "concat_vehicle_name_and_no",
                  attrs: {
                    label: "車両名と車両番号をカンマ区切りで表示",
                    prop: "concat_vehicle_name_and_no"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.concat_vehicle_name_and_no,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "concat_vehicle_name_and_no", $$v)
                        },
                        expression: "form.concat_vehicle_name_and_no"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("車両名のみ")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("区切って表示")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h2", [_vm._v("点呼記録簿関連")]),
              _c(
                "a-form-model-item",
                {
                  ref: "multiple_alcohol_check",
                  attrs: {
                    label: "点呼記録を1日に複数回使用",
                    prop: "multiple_alcohol_check"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.multiple_alcohol_check,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "multiple_alcohol_check", $$v)
                        },
                        expression: "form.multiple_alcohol_check"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("1回のみ")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("複数回")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "can_update_alcohol_measurement_result",
                  attrs: {
                    label: "アルコール濃度の編集",
                    prop: "can_update_alcohol_measurement_result"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.can_update_alcohol_measurement_result,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "can_update_alcohol_measurement_result",
                            $$v
                          )
                        },
                        expression: "form.can_update_alcohol_measurement_result"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("不可")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("可能")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "skip_roll_call_before",
                  attrs: {
                    label: "乗車前点呼のスキップ",
                    prop: "skip_roll_call_before"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.skip_roll_call_before,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "skip_roll_call_before", $$v)
                        },
                        expression: "form.skip_roll_call_before"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("不可")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("可能")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "separation_of_days",
                  attrs: {
                    label: "1日の区切り時間",
                    prop: "separation_of_days"
                  }
                },
                [
                  _c("a-time-picker", {
                    attrs: { format: "HH:mm" },
                    on: { change: _vm.onChangeSeparationOfDays },
                    model: {
                      value: _vm.separationOfDays,
                      callback: function($$v) {
                        _vm.separationOfDays = $$v
                      },
                      expression: "separationOfDays"
                    }
                  }),
                  _c("p", [
                    _vm._v("点呼記録簿の日付計算のために使用されます。")
                  ])
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "memo_for_operator",
                  attrs: { label: "点呼代行オペレーターへのメモ" }
                },
                [
                  _c("a-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.memo_for_operator,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "memo_for_operator", $$v)
                      },
                      expression: "form.memo_for_operator"
                    }
                  })
                ],
                1
              ),
              _c("h2", [_vm._v("オペレータ関連")]),
              _c(
                "a-form-model-item",
                {
                  ref: "roll_call_agent",
                  attrs: {
                    label: "点呼代行を行う会社か",
                    prop: "roll_call_agent"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.roll_call_agent,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "roll_call_agent", $$v)
                        },
                        expression: "form.roll_call_agent"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("行わない")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("オペレータを持ち、点呼代行の電話を受け付ける")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h2", [_vm._v("違反マップ関連")]),
              _c(
                "a-form-model-item",
                {
                  ref: "limit_latitude_left_top",
                  attrs: {
                    label: "データ取得制限：左上（緯度）",
                    prop: "limit_latitude_left_top",
                    extra: "無制限に取得可能にする場合は0を入力してください。"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.limit_latitude_left_top,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "limit_latitude_left_top", $$v)
                      },
                      expression: "form.limit_latitude_left_top"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "limit_longitude_left_top",
                  attrs: {
                    label: "データ取得制限：左上（経度）",
                    prop: "limit_longitude_left_top",
                    extra: "無制限に取得可能にする場合は0を入力してください。"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.limit_longitude_left_top,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "limit_longitude_left_top", $$v)
                      },
                      expression: "form.limit_longitude_left_top"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "limit_latitude_right_bottom",
                  attrs: {
                    label: "データ取得制限：右下（緯度）",
                    prop: "limit_latitude_right_bottom",
                    extra: "無制限に取得可能にする場合は0を入力してください。"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.limit_latitude_right_bottom,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "limit_latitude_right_bottom", $$v)
                      },
                      expression: "form.limit_latitude_right_bottom"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "limit_longitude_right_bottom",
                  attrs: {
                    label: "データ取得制限：右下（経度）",
                    prop: "limit_longitude_right_bottom",
                    extra: "無制限に取得可能にする場合は0を入力してください。"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.limit_longitude_right_bottom,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "limit_longitude_right_bottom", $$v)
                      },
                      expression: "form.limit_longitude_right_bottom"
                    }
                  })
                ],
                1
              ),
              _c("h2", [_vm._v("API用項目")]),
              _c(
                "a-form-model-item",
                {
                  ref: "username",
                  attrs: { label: "API用ユーザー名", prop: "username" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.username.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.username,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "password",
                  attrs: { label: "API用パスワード", prop: "password" }
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "api_vehicle",
                  attrs: { label: "API用車両ID", prop: "api_vehicle" }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.api_vehicle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "api_vehicle", $$v)
                      },
                      expression: "form.api_vehicle"
                    }
                  })
                ],
                1
              ),
              _c("hr"),
              _c("h2", [_vm._v("解析プログラムのパラメータ")]),
              _c(
                "a-form-model-item",
                {
                  ref: "termination_rate",
                  attrs: {
                    label: "一時停止判定の速度",
                    prop: "termination_rate"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0, max: 20 },
                    model: {
                      value: _vm.form.termination_rate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "termination_rate", $$v)
                      },
                      expression: "form.termination_rate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "overspeed",
                  attrs: { label: "速度超過のしきい値", prop: "overspeed" }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.form.overspeed,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "overspeed", $$v)
                      },
                      expression: "form.overspeed"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.submitString) + "\n      "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: _vm.backPath } },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v("\n          戻る\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }